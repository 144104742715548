import React, { useEffect, useCallback } from 'react';
import { parse, isValid } from 'date-fns';
// import fr from 'date-fns/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getQuizAction, getSessionAction } from '../../actions/quizActions';
import { SET_TOKEN } from '../../actions/types';
import styles from './End.module.scss';

export default function End() {
  const params = useParams();
  const { token } = params;
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.quiz);

  const getData = useCallback(() => {
    getQuizAction(dispatch);
    getSessionAction(dispatch);
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      if (!session) getData();
    }
  }, [token, session, getData]);

  const options = {
    month: 'long', day: 'numeric',
  };

  function getDate() {
    const date = parse(session?.diffusion_evenement_date, 'd/M/yy', new Date());
    let res;
    if (isValid(date)) res = new Intl.DateTimeFormat('fr', options).format(new Date(date));
    return res;
  }

  function getHour() {
    let res;
    if (session.diffusion_evenement_heure) res = ` ${session.diffusion_evenement_heure.replace(':', 'H')}`;
    res = res.replace(/00$/, '');
    return res;
  }

  return (
    <div className={`page-content ${styles.container}`}>
      <div className={styles.serie}>
        <h2>MERCI POUR VOTRE PARTICIPATION</h2>
        {session?.diffusion_evenement_nom && (
         <>
          <h3>Prochaine ANIMATION</h3>
          <div className={styles.theme}>
            {getDate() && <p>Le {getDate()} <span className={styles.hours}>{getHour()}</span></p>}
            <h2>{session.diffusion_evenement_nom}</h2>
          </div>
         </>
        )}
      </div>
    </div>
  );
}
