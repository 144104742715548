import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Lottie from "react-lottie";
import { replaceColor } from "lottie-colorify";
import { withWindowSize } from "react-fns";
import { chunk, cloneDeep, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getQuizAction,
  getSessionAction,
  getResultsAction,
} from "../../actions/quizActions";
import { GET_RESULTS, SET_TOKEN } from "../../actions/types";
import { textAbstract } from "../../utils/utils";
// import logo from "../../assets/images/logo.svg";
import animationData from "./winning-cup.json";
import styles from "./Results.module.scss";

// Durée pendant laquelle l'écran résultat est affiché
const MINUTES = 10;

// [255, 235, 153] #ffeb99 -> #8f90bf
// [255, 240, 0] #FFF000 -> #515283
// [255, 222, 85] #FFDE55 -> #6f70a3
// [255, 205, 0] #FFCD00 -> #575891
// 255, 176, 0] #FFB000 -> #2D2E5C

let cupsColored = replaceColor([255, 235, 153], "#8f90bf", animationData);
cupsColored = replaceColor([255, 240, 0], "#515283", cupsColored);
cupsColored = replaceColor([255, 222, 85], "#6f70a3", cupsColored);
cupsColored = replaceColor([255, 205, 0], "#575891", cupsColored);
cupsColored = replaceColor([255, 176, 0], "#3a3b6b", cupsColored);
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: cupsColored,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Results = ({ width }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const timerRef = useRef();
  const [firstTime, setFirstTime] = useState(true);
  const [rankingPage, setRankingPage] = useState(0);
  // const [chunkTeams, setChunkTeams] = useState();
  // const [itemPerPage, setItemPerPage] = useState();
  const { token } = params;
  const { session, step, results } = useSelector((state) => state.quiz);
  let arr = results
    ? orderBy([...results], ["score", "nom"], ["desc", "asc"])
    : [];

  arr = arr.filter((d) => d.score > 0);

  let position = 0;
  const sortedResults = arr.map((d, i) => {
    let item;
    if (arr[i - 1] && arr[i - 1].score === d.score) {
      item = { ...d, position: null };
    } else {
      position++;
      item = { ...d, position };
    }
    return item;
  });

  const othersClassName = `${styles.team} ${styles.others}`;

  function getChunk() {
    let itemPerPage = 12;

    // if (width < 1220) {
    //   itemPerPage = 10;
    // } else
    if (width < 1320) {
      itemPerPage = 10;
    }

    return {
      chunkTeams: chunk(
        cloneDeep(sortedResults).slice(3, sortedResults.length),
        itemPerPage,
      ),
      itemPerPage,
    };
  }

  const { chunkTeams } = getChunk();

  const getData = useCallback(() => {
    getQuizAction(dispatch);
    getSessionAction(dispatch);
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  }, [dispatch, token]);
  // handle update rankingPage
  useEffect(() => {
    if (chunkTeams.length <= 1) return null;
    timerRef.current = setTimeout(() => {
      setFirstTime(false);
      setRankingPage((state) => state + 1);
    }, 10000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [timerRef, rankingPage, chunkTeams]);

  useEffect(() => {
    setTimeout(() => {
      history.push(`/end/${token}`);
    }, 1000 * 60 * MINUTES);
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      if (!session) getData();
    }
  }, [token, session, getData]);

  function getDelay(value) {
    return `${value}s`;
  }

  function getPosition(value) {
    return `${value <= 9 ? "0" : ""}${value}`;
  }

  function getTeamImage(team) {
    let source;
    const { media } = team;
    if (media?.length > 0) {
      source = team.mediaBaseUrl + media[media.length - 1].nom;
    }
    return source;
  }

  useEffect(() => {
    if (token) getResultsAction(dispatch);
    return () => dispatch({ type: GET_RESULTS, payload: null });
  }, [step?.results, token]);

  function getIndex() {
    const page = rankingPage;
    return page % chunkTeams.length;
  }

  return (
    <div className={styles.container}>
      <div className={styles.first}>
        <div className={styles.top}>
          {sortedResults.map((t, i) => {
            if (i < 3) {
              const name = textAbstract(t.nom, 47);
              const delay = getDelay(i * 0.5);
              return (
                <div
                  key={`top-team-${t.id}-${i}`}
                  style={{ animationDelay: delay }}
                  className={styles.team}
                >
                  <div className={styles["container-image"]}>
                    {t.media.length > 0 ? (
                      <img src={getTeamImage(t)} alt={name} />
                    ) : (
                      <div className={styles["container-lottie"]}>
                        <Lottie height={'100%'} width={'50%'} options={defaultOptions} />
                      </div>
                    )}
                  </div>
                  <h2 className={!t.position ? styles.egality : ""}>
                    {t.position ? getPosition(t.position) : " - "}
                  </h2>
                  <h3>
                    <span className={styles.name}>{name}</span>
                    <br />
                    <span className={styles.score}>{t.score}</span>
                    <span className={styles.pts}> PTS</span>
                  </h3>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      <div className={styles.all}>
        {chunkTeams[rankingPage % chunkTeams.length] && (
          <>
            {
              chunkTeams[getIndex()].map((t, i) => {
                let name = textAbstract(t.nom, 34);
                if (width < 1320) {
                  name = textAbstract(t.nom, 24);
                }
                let delay = 0;
                if (firstTime) {
                  delay = (i + 3) * 0.5;
                } else {
                  delay = i * 0.5;
                }
                return (
                  <div
                    key={`team-${t.id}-${i}-${rankingPage}`}
                    style={{ animationDelay: `${delay}s` }}
                    className={othersClassName}
                  >
                    <h2 className={!t.position ? styles.egality : ""}>
                      {t.position ? getPosition(t.position) : " - "}
                    </h2>
                    <h3>
                      <span className={styles.name}>{name}</span>
                      <br />
                      <span className={styles.score}>{t.score}</span>
                      <span className={styles.pts}> PTS</span>
                    </h3>
                  </div>
                );
              })
            }
          </>
        )}
      </div>
    </div>
  );
};

export default withWindowSize(Results);
