import { getData } from './index';
import {
  GET_QUIZ,
  GET_SESSION,
  ERROR,
  GET_RESULTS,
} from './types';
import { prepareTeamsForApp } from '../utils/utils.js';

export async function getQuizAction(dispatch) {
  const url = 'quiz/series';
  let series;
  await getData(ERROR, url, dispatch, true).then((response) => {
    series = response.series;
  });
  dispatch({
    type: GET_QUIZ,
    payload: series,
  });
}

export async function getSessionAction(dispatch) {
  const url = 'quiz/session';
  let session;
  await getData(ERROR, url, dispatch, true).then((response) => {
    session = response.session;
  });
  dispatch({
    type: GET_SESSION,
    payload: session,
  });
}

export async function getResultsAction(dispatch) {
  const url = 'quiz/teams';
  let teams;
  await getData(ERROR, url, dispatch, true).then((response) => {
    teams = response.teams;
  });
  const updatedTeams = prepareTeamsForApp(teams);
  // const updatedTeams = teams.map((team) => {
  //   const clone = Object.assign(team, {});
  //   clone.series = [{ score: 10 }, { score: 8 }];
  //   clone.score = getRandomInt(500);
  //   clone.bonus = null;
  //   return clone;
  // });

  dispatch({
    type: GET_RESULTS,
    payload: updatedTeams,
  });
}
