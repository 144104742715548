import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import QRCode from "react-qr-code";
import logo from "../../assets/images/logo.svg";
import logoFooter from "../../assets/images/logo-cotton-display.png";
// import illu from "../../assets/images/illu.png";
import quoteImg from "../../assets/images/quote.svg";
import styles from "./Footer.module.scss";
// import { textAbstract } from "../../utils/utils";

export default function Footer() {
  const history = useHistory();
  const { step, session } = useSelector((state) => state.quiz);
  const [widthQRCode, setWidthQRCode] = useState(200);
  const cottonUrl = "https://www.cotton-quiz.com/espace/joueur";

  useEffect(() => {
    function setQRCodeSize() {
      if (window.innerWidth > 0) {
        const width = window.innerWidth;
        let res = 190;
        if (width < 1100) {
          res = 150;
        } else if (width < 1200) {
          res = 180;
        }
        setWidthQRCode(res);
      }
    }
    setQRCodeSize();
    window.addEventListener("resize", setQRCodeSize);

    return () => window.removeEventListener("resize", setQRCodeSize);
  }, []);

  function getRewards() {
    const rewards = [];
    if (session?.lot_1) rewards.push(session?.lot_1);
    if (session?.lot_2) rewards.push(session?.lot_2);
    if (session?.lot_3) rewards.push(session?.lot_3);
    return rewards;
  }

  function getSessionImage() {
    let source;
    if (session) {
      let media;
      let mediaBaseUrl;
      const { client } = session;
      if (session.media && session.media.length > 0) {
        media = session.media;
        mediaBaseUrl = session.mediaBaseUrl;
      } else if (client) {
        media = client.media;
        mediaBaseUrl = client.mediaBaseUrl;
      }

      let img;
      if (media?.length > 0) {
        img = media[media.length - 1];
        source = `${mediaBaseUrl}${img?.nom}`;
      }

      if (!source) {
        source = logoFooter;
      }
    }
    return source;
  }

  function getClient() {
    let content;
    if (session) {
      content = (
        <>
          <h3>{session.client.nom}</h3>
          <p>{session.client.ville}</p>
        </>
      );
      const clientLogos = session.clients_logos;
      const { media, mediaBaseUrl } = clientLogos;
      let img;
      if (media.length > 0) {
        img = media[media.length - 1];
        content = (
          <img src={`${mediaBaseUrl}${img?.nom}`} alt={session?.client.nom} />
        );
      }
    }
    return content;
  }

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.lot}`}>
          <div className={styles.col}>
            <p className={styles.label}>Lots à gagner</p>
          </div>
          {getRewards().map((p, i) => (
            <div key={i} className={styles.col}>
              <div className={styles.number}>
                <p>{i + 1}</p>
              </div>
              <p>{p}</p>
            </div>
          ))}
        </div>
        <div className={`${styles.row}`}>
          <div className={styles.client}>{getClient()}</div>
          {!history.location.pathname.startsWith("/results")
          && !history.location.pathname.startsWith("/end") && (
              <div className={styles.qrcode}>
                <div className={styles.code}>
                  <QRCode value={cottonUrl} size={widthQRCode} />
                </div>
              </div>
          )}
          <div className={styles.quote}>
            <img
              className={styles.left}
              src={quoteImg}
              alt={session?.client.nom}
            />
            <span>
              {session?.diffusion_message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: session?.diffusion_message,
                  }}
                />
              )}
            </span>
            <img
              className={styles.right}
              src={quoteImg}
              alt={session?.client.nom}
            />
          </div>
          <div className={styles.image}>
            <img src={getSessionImage()} alt="" />
          </div>
        </div>
      </div>
      {step?.data?.id && (
        <div className={styles.quiz}>
          <h4>COTTON QUIZ</h4>
          <p>EN COURS</p>
        </div>
      )}
      {step?.path !== "results"
      && !history.location.pathname.startsWith("/results") && (
          <div className={styles.logo}>
            <img src={logo} alt="" />
            <p>Cultures de Comptoirs !</p>
          </div>
      )}
    </>
  );
}
