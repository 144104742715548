import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getQuizAction, getSessionAction } from "../../actions/quizActions";
import { SET_TOKEN } from "../../actions/types";
import styles from "./Stop.module.scss";

export default function Stop() {
  const params = useParams();
  const { token } = params;
  const dispatch = useDispatch();
  const { step, session, series } = useSelector((state) => state.quiz);
  const serie = step?.data;

  const getData = useCallback(() => {
    getQuizAction(dispatch);
    getSessionAction(dispatch);
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      if (!session) getData();
    }
  }, [token, session, getData]);

  function getNextUnivers() {
    const nextIndex = series.findIndex((d) => d.id === serie.id) + 1;
    const nextSerie = series[nextIndex];
    let univers = nextSerie?.questions[0]?.univers;
    if (nextSerie?.nom) univers = nextSerie.nom;
    return univers;
  }

  return (
    <div className={`page-content ${styles.container}`}>
      {serie && (
        <div className={styles.serie}>
          <h1>PAUSE</h1>
          <div className={styles.theme}>
            <p>Prochain thème</p>
            <h2>{getNextUnivers()}</h2>
          </div>
        </div>
      )}
    </div>
  );
}
