import axios from 'axios';

// const DEV_URL = 'http://192.168.86.88:30040/api/';
// const DEV_URL = 'https://api.cottonquiz.bakasable.io/api/';
export const API_URL = 'https://api.cotton-quiz.com/api/';

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error, error.response);
  if (error?.response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    dispatch({
      type: 'ERROR_AUTH',
      payload: error.response.status,
    });
  } else {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export function getData(errorType, url, dispatch, isAuthReq = false) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: token,
      },
    };
    axios.get(requestUrl, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}
