// AUTH ACTIONS
export const ERROR = 'ERROR';
export const MESSAGE = 'MESSAGE';
export const GET_QUIZ = 'GET_QUIZ';
export const GET_SESSION = 'GET_SESSION';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_STEP = 'SET_STEP';
export const SET_QUESTION = 'SET_QUESTION';
export const SET_MEDIA = 'SET_MEDIA';
export const GET_RESULTS = 'GET_RESULTS';
