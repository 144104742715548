import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getQuizAction, getSessionAction } from '../../actions/quizActions';
import { SET_TOKEN } from '../../actions/types';
import styles from './Serie.module.scss';

export default function Serie() {
  const params = useParams();
  const { token } = params;
  const dispatch = useDispatch();
  const {
    step,
    session,
    question,
    bonus,
  } = useSelector((state) => state.quiz);
  const getData = useCallback(() => {
    getQuizAction(dispatch);
    getSessionAction(dispatch);
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      if (!session) getData();
    }
  }, [token, session, getData]);

  const serie = step?.data;
  const total = serie?.questions.reduce((accumulator, d) => accumulator + d.points.points, 0) || 0;

  function getUnivers() {
    let univers = serie?.questions[0]?.univers;
    if (serie.nom) univers = serie.nom;
    return univers;
  }

  function getNumero() {
    const targetQuestion = serie?.questions.find((d) => d.id === question);
    return targetQuestion?.numero;
  }

  return (
    <div className={`page-content ${styles.container}`}>
      {serie && <div className={styles.serie}>
        <h4>Série {serie.numero}</h4>
        <div className={styles.theme}>
          <p> thème</p>
          <h2>{getUnivers()}</h2>
        </div>
        <div className={styles.points}>
          <h3><span>{total}</span> PTS</h3>
        </div>
        {(typeof question === "number") && <p className={styles.question}>Question N°{getNumero()}</p>}
        {bonus && <p className={styles.question}>Indice bonus</p>}
      </div>}
    </div>
  );
}
