import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from "react-player/lazy";
import styles from "./Media.module.scss";
import { SET_MEDIA } from "../../actions/types";

const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};

  // iterate over all keys
  Array.from(params.keys()).forEach((key) => {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  });

  return obj;
};

export default function Media({ isMuted, setIsMuted }) {
  const { media } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  let searchParams = null; // for youtube video
  let [url, searchString] = [];
  // const media = step?.media;

  // for youtube video
  if (media?.type) {
    const split = media?.src.split("?");
    [url, searchString] = split;
    searchParams = parseParams(searchString || "");
  }

  function getVideoId(src) {
    let idVideo;
    if (src.includes("https://youtu.be/")) {
      const split = src.split("/");
      idVideo = split[split.length - 1];
    } else if (src.includes('https://www.youtube.com/embed/')) {
      idVideo = src.replace('https://www.youtube.com/embed/', '');
    }
    if (idVideo && idVideo.includes("&")) {
      const split = idVideo.split("&");
      [idVideo] = split;
    }
    return idVideo;
  }

  function getSearchParams() {
    const obj = { ...searchParams };
    delete obj.v;
    return obj;
  }

  return (
    <div
      onClick={() => setIsMuted(false)}
      className={`${styles.container} ${
        media?.src ? styles["is-active"] : ""
      } ${isMuted ? styles["is-muted"] : ""}`}
    >
      {media?.legend && (
        <div className={styles.legend}>
          <p>série : {media.step.numero}</p>
          <p>
            {media?.answer ? "Réponse " : "Question "}
            <span>n°{media?.legend.numero}</span>
          </p>
          {/*
        <p>
            sous-THèME : <span>{media.legend.rubrique}</span>
          </p>
          */}
        </div>
      )}
      {media?.type === "image" && (
        <div className={styles["container-image"]}>
          <img src={media?.src} alt="image" />
        </div>
      )}
      {media?.type === "audio" && (
        <audio
          className="test"
          autoPlay
          onEnded={() => {
            dispatch({
              type: SET_MEDIA,
              payload: null,
            });
          }}
          src={media?.src}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )}
      {(media?.type === "audio" || media?.typeSupport === "audio") && (
        <>
          <div className={styles["audio-animation"]}>
            <div className={styles.soundIcon}>
              <div className={styles.soundWave}>
                {new Array(40).fill(null).map((d, i) => <div key={`bar-${i}`} className={styles.bar}></div>)}
              </div>
            </div>
          </div>
        </>
      )}
      {media?.type === "video" && (
        <span
          className={styles.youtube}
          style={media?.typeSupport === "audio" ? { visibility: "hidden" } : {}}
        >
          <ReactPlayer
            url={`https://www.youtube.com/embed/${searchParams.v ? searchParams.v : getVideoId(url)}`}
            className={`${styles.video} ${
              media?.src ? styles["is-playing"] : ""
            }`}
            width="100%"
            height="100%"
            onStart={() => setIsMuted(false)}
            onEnded={() => {
              dispatch({
                type: SET_MEDIA,
                payload: null,
              });
            }}
            muted={isMuted}
            config={{
              youtube: {
                playerVars: (getSearchParams() && Object.keys(getSearchParams()).length > 0)
                  ? getSearchParams()
                  : {
                    showinfo: 0,
                    iv_load_policy: 0,
                  },
              },
            }}
            playing={true}
          />
        </span>
      )}
      {isMuted && (
        <button onClick={() => setIsMuted(true)}>Démarrer le quiz</button>
      )}
    </div>
  );
}
