import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getQuizAction, getSessionAction } from '../../actions/quizActions';
import { SET_TOKEN } from '../../actions/types';
import icon from '../../assets/images/home-icon.svg';
import styles from './Home.module.scss';

export default function Home() {
  const params = useParams();
  const { token } = params;
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.quiz);

  const getData = useCallback(() => {
    getQuizAction(dispatch);
    getSessionAction(dispatch);
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      getData();
    }
  }, [token, getData]);

  const options = {
    weekday: 'long', month: 'long', day: 'numeric',
  };

  function getDateStr() {
    const date = new Intl.DateTimeFormat('fr', options).format(new Date(session.date));
    return date[0].toUpperCase() + date.slice(1, date.length);
  }

  function getHours() {
    let hours = session.heure_debut;
    hours = hours.replace(/00$/, '');
    return hours.toLowerCase();
  }

  return (
    <div className={`page-content ${styles.container}`}>
      {session
        && <div className={styles.box}>
          <div className={styles.date}>
            <img className={styles.left} src={icon} alt='icon' />
            <h2>{getDateStr()}</h2>
            <img className={styles.right} src={icon} alt='icon' />
          </div>
          <h1 className={styles.hours}>{getHours()}</h1>
          <h1 className={styles.cotton}>COTTON QUIZ</h1>
        </div>
      }
    </div>
  );
}
