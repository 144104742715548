import {
  ERROR,
  MESSAGE,
  GET_QUIZ,
  SET_QUESTION,
  GET_SESSION,
  SET_TOKEN,
  SET_STEP,
  SET_MEDIA,
  GET_RESULTS,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  series: null,
  session: null,
  results: null,
  step: null,
  media: null,
  token: null,
  bonus: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESULTS:
      return { ...state, results: action.payload };
    case SET_QUESTION:
      return {
        ...state,
        media: null,
        step: action.payload,
        question: action.payload.question,
        bonus: action.payload.bonus,
      };
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
        question: action.payload.question,
      };
    case SET_MEDIA:
      return { ...state, media: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case GET_SESSION:
      return { ...state, session: action.payload };
    case GET_QUIZ:
      return { ...state, series: action.payload };
    case MESSAGE:
      return { ...state, message: action.payload };
    case ERROR:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
