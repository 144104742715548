export default function setVhUnit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export function textAbstract(value, length) {
  let text = value;
  if (text == null) {
    return '';
  }
  if (text.length <= length) {
    return text;
  }
  text = text.substring(0, length);
  const last = text.lastIndexOf(' ');
  text = text.substring(0, last);
  return `${text}...`;
}

export function convertPointsToSeriesScore(points) {
  const series = [];

  if (points) {
    let i = 1;
    while (typeof points[`equipe_quiz_serie_${i}_points`] === 'number') {
      series.push({
        score: points[`equipe_quiz_serie_${i}_points`],
      });
      i++;
    }
  }

  return {
    series,
    score: points.equipe_quiz_points,
    bonus: points.equipe_quiz_bonus_points === -1 ? null : points.equipe_quiz_bonus_points,
  };
}

export function prepareTeamsForApp(teams) {
  const arr = [];

  teams.forEach((team) => {
    const result = convertPointsToSeriesScore(team.points);
    arr.push({
      ...team,
      ...result,
    });
  });

  return arr;
}

export function getMediaType(type) {
  let t = '';
  switch (type) {
    case 1:
      t = 'image';
      break;
    case 2:
      t = 'audio';
      break;
    case 3:
      t = 'video';
      break;
    default:
      t = 'video';
      break;
  }
  return t;
}
